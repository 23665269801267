@import 'react-redux-toastr/src/styles/index';
@import './../styles/mixins.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

body {
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

/*Стилизация скроллбара*/
/* Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 #f4f4f4;
}

/* Chrome, Edge и Safari */
#__next div[data-scrollbar='styles']::-webkit-scrollbar {
  height: 8px;
}

#__next div[data-scrollbar='styles']::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 4px;
}

#__next div[data-scrollbar='styles']::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
}

#nprogress .bar {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.bookCardsList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 20px;
  @include max-screen-375 {
    justify-content: center;
  }
}

.h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  padding: 0;
  line-height: 1.2;
}

.btnSmall {
  display: inline-block;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  padding: 7px 16px;
  border-radius: 5px;
  font-weight: 400;
  background: #5976db;
  color: #fff;
}

.mainBtn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  padding: 12px 32px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  background: #5976db;
  color: #fff;
  transition: background 0.3s ease;
  &:hover {
    background: #7a91e2;
  }
  &_fullWidth {
    width: 100%;
  }
}

#audio_player {
  .rhap_additional-controls {
    display: none;
  }
  .rhap_time {
    font-size: 14px;
    color: #575757;
  }
  .rhap_container {
    padding: 10px 10px;
  }
}
